<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <!-- Header -->
    <main-header />
    <!--  -->
    <div class="container">
      <!-- Breadcrumb -->
      <div class="_flex _center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">Oeuvres</li>
          </ol>
        </nav>
      </div>
    </div>
    <!--  -->
    <section class="d-none d-lg-block my-3 my-4-lg">
      <div class="container">
        <ul v-if="allCategories" class="_flex _center _wrap list-unstyled body-2 medium mb-5-md mb-7-lg" id="facets">
          <li class="mb-3" style="background: rgba(0,0,0,.5)">
            <router-link to="/oeuvres" class="c-pointer">
              <h2 class="text-capitalize text-black">Toutes</h2>
            </router-link>
          </li>
          <li class="mb-3" @click="filterCategory(category.category_id)" v-for="category in allCategories" :key="category.category_id.substring(5,15)">
            <a class="c-pointer">
              <img width="100" height="60" class="img-responsive img-tint" :src="category.img_src" :alt="category.name"/>
              <h2 class="text-capitalize">{{ category.name }}</h2>
            </a>
          </li>
        </ul>
      </div>
    </section>
    <!-- Other filters -->
<!--    <section class="d-none d-lg-block" style="background-color:#000;color:white;" id="fixedFilterBlack">
      <div class="container">
        <ul class="_flex _center _wrap subheading list-unstyled bold">
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn _flex">
              Prix
              <span class="ml-1 bg-white rounded-pill px-1 medium" style="font-size:12px;height:20px;line-height:20px;">0 $ | 1000 $</span>
              <i class="fal fa-angle-down ml-2"></i>
            </a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-price">
              <div class="row no-gutters text-black">
                <div class="col-12">
                  <div class="px-4 subheading">
                    <div>O $ <span style="color:#ccc">|</span> 1000 $</div>
                    <div class="mt-2 mb-4"></div>
                  </div>
                  <div class="border-top px-3 price__reinit" style="height:24px;line-height:48px;">
                    <div class="_flex c-pointer">
                      <i class="icon-quit mx-2"></i> Réinitialiser le filtre
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Thème <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-theme">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="#">Abstraite</a>
                  <a href="#">Street art</a>
                  <a href="#">Nus</a>
                  <a href="#">Nature morte</a>
                  <a href="#">Fantastique</a>
                  <a href="#">Flore</a>
                  <a href="#">Art aborigène</a>
                  <a href="#">Nature</a>
                  <a href="#">Mode</a>
                  <a href="#">Politique et historique</a>
                  <a href="#">Minimalisme</a>
                  <a href="#">Monumentale</a>
                  <a href="#">Gravure</a>
                  <a href="#">Art Oriental</a>
                  <a href="#">Art brut</a>
                  <a href="#">Conceptuel</a>
                  <a href="#">Cinéma</a>
                  <a href="#">Musique</a>
                  <a href="#">Nourriture</a>
                  <a href="#">Vanités</a>
                </div>
                <div class="col-6">
                  <a href="#">Architecture</a>
                  <a href="#">Icônes</a>
                  <a href="#">Portrait</a>
                  <a href="#">Paysage urbain</a>
                  <a href="#">Graphisme</a>
                  <a href="#">Sous l'eau</a>
                  <a href="#">Noir et blanc</a>
                  <a href="#">Scène de vie</a>
                  <a href="#">Animaux</a>
                  <a href="#">Pop Art</a>
                  <a href="#">Géométrique</a>
                  <a href="#">Sculpture murale</a>
                  <a href="#">Vu du ciel</a>
                  <a href="#">Art africain</a>
                  <a href="#">Jardin</a>
                  <a href="#">Bande dessinée</a>
                  <a href="#">Art tribal</a>
                  <a href="#">Sport</a>
                  <a href="#">Religion</a>
                  <a href="#">Vehicules</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Couleur <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-couleur">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="#">Argenté</a>
                  <a href="#">Blanc</a>
                  <a href="#">Bleu foncé</a>
                  <a href="#">Doré</a>
                  <a href="#">Gris</a>
                  <a href="#">Marron</a>
                  <a href="#">Orange</a>
                  <a href="#">Rouge</a>
                  <a href="#">Vert</a>
                </div>
                <div class="col-6">
                  <a href="#">Beige</a>
                  <a href="#">Bleu ciel</a>
                  <a href="#">Bleu marine</a>
                  <a href="#">Doré</a>
                  <a href="#">Jaune</a>
                  <a href="#">Noir</a>
                  <a href="#">Rose</a>
                  <a href="#">Transparent</a>
                  <a href="#">Violet</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Format <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-format">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="#">Petit (&lt; 20 x 20 inch)</a>
                  <a href="#">Moyen (&lt; 45 x 45 inch)</a>
                  <a href="#">Grand (> 45 x 45 inch)</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Orientation <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-orientation">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="#">Portrait</a>
                  <a href="#">Paysage</a>
                  <a href="#">Carré</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Tirage <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-tirage">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="#">Unique</a>
                  <a href="#">Multiple</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>-->
    <div class="d-none d-lg-block p-2 border-bottom">
      <div class="container">
        <ul class="_flex _center body-1 text-muted list-unstyled">
          <li class="_flex mr-5 mr-10-lg mr-12-xl">
            Catégorie : <strong class="text-capitalize ml-1">{{ selectedCategory ? selectedCategory.name : 'Toutes' }}</strong>
          </li>
          <li class="_flex ">{{ artworks.length }} résultat{{ artworks.length > 1 ? "s" : "" }}</li>
        </ul>
      </div>
    </div>
    <!--  -->
    <section class="container d-block d-lg-none">
      <div class="_flex _between subheading">
        <div class="bg-black mr-2 w-100 p-3 text-center">Trier</div>
        <div class="bg-black ml-2 w-100 p-3 text-center">Filtrer</div>
      </div>
    </section>
    <!--  -->
    <div class="container px-15-lg px-20-xl">
      <div class=" my-10">
        <!-- <div class="row d-flex flex-row flex-wrap _center my-10"> -->
        <div class="my-10">
          <div class="card-columns" v-if="artworks.length > 0">
            <!-- -->
            <artwork-card v-for="artwork in artworks" :artwork="artwork" :key="artwork.artwork_id"/>
          </div>
          <template v-else>
            <no-results />
          </template>
        </div>
      </div>
    </div>
    <!--  -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    artworks: [],
    allCategories: [],
    selectedCategory: null,
  }),
  computed: {
    ...mapGetters([])
  },
  watch: {
    $route(to) {
      const query_category_id = to.query.category_id;
      if(query_category_id){
        this.getArtworksByCategory(query_category_id)
      }else {
        this.getArtworks()
      }
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetOneData",
        "axiosGetAllData"
    ]),
    filterCategory(category_id) {
      this.$router.push({
        path: "/oeuvres",
        query: {
          category_id: category_id,
        },
      });
    },
    getArtworksByCategory(category_id){
      this.loading = true
      this.selectedCategory = this.allCategories.filter( category => category.category_id === category_id )[0]
      this.axiosGetOneData({table: "retrieveArtworksByCategory", table_id: category_id})
          .then( response => {
            if(response.status === 200){
              this.artworks = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artworks = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    },
    getArtworks(){
      this.loading = true;
      this.axiosGetAllData({table: "retrieveArtworks"})
          .then( response => {
            if(response.status === 200){
              this.artworks = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artworks = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    },
    getCategories(query_category_id){
      this.loading = true;
      this.axiosGetAllData({table: "retrieveCategories"})
          .then( response => {
            if(response.status === 200){
              this.allCategories = response.data.data
              this.selectedCategory = query_category_id ? this.allCategories.filter( category => category.category_id === query_category_id )[0] : null
            }
            this.loading = false;
          })
          .catch()
    }
  },
  mounted() {},
  created() {
    let query_category_id = this.$route.query.category_id
    this.getCategories(query_category_id)
    if(query_category_id){
      this.getArtworksByCategory(query_category_id)
    }else {
      this.getArtworks()
    }
  },
};
</script>

<style></style>
